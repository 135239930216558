import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/code/app/_components/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DevMenu"] */ "/code/app/_components/DevMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackPageView"] */ "/code/app/_components/TrackPageView.tsx");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/code/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"preload\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/code/css/index.scss");
